import React from "react";


const ProjectCard = ({project_image, project_title, project_description, project_link})=>{
    return(
        <div className="cursor-pointer col-md-4 p-1 lg:p-3 px-2">
            <div className='border rounded-md p-2' onClick={()=>{window.location.href=project_link}}>
                <div className='p-1 max-w-50'>
                    <div className='rounded-sm p-3 min-h-28 max-h-28'>
                        <img src={project_image} alt="API Weaver/Weather" className="overflow-hidden min-h-28 max-h-28 w-100 object-contain"/>
                    </div>
                    <p className='font-bold text-center pt-5'>{project_title}</p>
                    <p className="text-sm">
                        {project_description}
                    </p>
                </div>
            </div>
        </div>
    )
}


export default ProjectCard;