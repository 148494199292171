import React from "react";


const TitleHeader = ({title, subtitle}) => {
    return(
        <div className="py-5 px-4 bg-gray-50 w-100">
            <p className="font-bold text-3xl font-primary text-zinc-700">{ title }</p>
            <p className="text-sm text-gray-500 font-primary">{ subtitle }</p>
        </div>
    );
}

export default TitleHeader;