import React from "react";
import { motion, useAnimation } from "framer-motion";

import { Tooltip } from '@chakra-ui/react'


const SkillCardTemplate = ({ skill_name, skill_description, skill_image, badge_item }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const controls = useAnimation();

    const handleMouseEnter = () => {
        setIsHovered(true);
        controls.start({ scale: [1, 0.9, 1.2, 1], transition: { duration: 0.35 } });

    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        controls.start({ scale: 1, transition: { duration: 0.5 } });
    };

    return (
        <div
            className={`border skill-card py-5 px-3  h-100 rounded-lg ${
                isHovered ? "hovered" : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="d-flex align-items-end">
                <motion.img
                    src={skill_image}
                    alt="skill_icon"
                    className="pl-5 w-32 md:w-[120px] md:h-[120px] h-[120px] max-h-[120px] max-w-[120px]"
                    animate={controls}
                />

                <div className="ml-5 d-flex flex-wrap">
                    {
                        badge_item !== undefined &&
                        badge_item.map((item) => (
                            <Tooltip label={item[1]} aria-label='A tooltip'>
                                <img src={item[0]} className="md:w-12 md:h-12 w-[2.5rem] h-[2.5rem] ml-2 mb-1" alt="badge_icon" />
                            </Tooltip>
                        ))
                    }
                </div>
            </div>

            

            <div className="pl-4 pt-2">
                <p className={`text-1xl font-bold mt-3 border-s-2 pl-4 border-color-primary  ${isHovered ? ' color-primary ' : ''}` }>
                    {skill_name}
                </p>

                

                <p className="font-primary mt-3 p-2 text-sm color-black pl-4 leading-relaxed">
                    {skill_description}
                </p>
            </div>
        </div>
    );
};

export default SkillCardTemplate;
