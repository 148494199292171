import axios from 'axios';

const baseAPIURL = "https://iammark.pythonanywhere.com";
const API_auth_cred = "";

/*
const fecthData = async (from_server) => {
    const response = await axios.get(from_server);
    const data = response.data;
    return data
}
*/

const fetchDataAuthenticated = async (api_request_url) => {
  const request_instance = axios.create({
    baseAPIURL,
    timeout: 10000,
    headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });
  const api_responce = await request_instance.get(baseAPIURL + api_request_url);
  return api_responce.data;
}


const sendDataAuthenticated = async (api_request_url, data)=>{
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const request_instance = axios.create({
    baseAPIURL,
    timeout: 60000,
    headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });

  try {
    const response = await request_instance.post(baseAPIURL + api_request_url, data, config);
    if (response.status === 201) {
      alert('Data sent successfully.');
    } else {
      alert('Something went wrong.');
    }
  } catch (error) {
    alert('Error occurred while sending data. with code: ' + error);
  }

}

const sendData = async (api_url, data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

    try {
      const response = await axios.post(api_url, data, config);
      if (response.status === 201) {
        alert('Data sent successfully.');
      } else {
        alert('Something went wrong.');
      }
    } catch (error) {
      alert('Error occurred while sending data. with code: ' + error);
    }
  };
  
  











/** New Auths **/
//const base_url = "http://localhost:8000";
const base_url = "https://marktestserver.pythonanywhere.com";


const client_api = axios.create({
  baseURL: base_url,
});






const getRequest = async (api_uri) => {
  let acctoken = sessionStorage.getItem("acctoken");
  const api_request = await client_api.get(api_uri, {
    headers: {
      "Authorization": `TOKEN ${acctoken}`
    }
  });
  return api_request;
}


const postRequest = async (api_uri, data, with_creds=false) => {
  let acctoken = sessionStorage.getItem("acctoken");
  const api_request = await client_api.post(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data", 
      "Authorization": `TOKEN ${acctoken}`
    }
  });
  return api_request;
}


const patchRequest = async (api_uri, data, with_creds=false) => {
  let acctoken = sessionStorage.getItem("acctoken");
  const api_request = await client_api.patch(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      "Authorization": `TOKEN ${acctoken}`
    }
  });
  return api_request;
}


const deleteRequest = async (api_uri, with_creds=false) => {
  let acctoken = sessionStorage.getItem("acctoken");
  const api_request = await client_api.delete(api_uri, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      "Authorization": `TOKEN ${acctoken}`
    }
  });
  return api_request;
}



const authVerify = async(callback=null)=>{
  await getRequest("/admin/auth/status/")
  .then(response => {
      if(response.status === 200){
        
        if(callback){
          callback(true);
        }
        return true;
      }
  }).catch(error => {
      window.location.href = '/';
  });
}



export { 
  sendData, 
  fetchDataAuthenticated, 
  sendDataAuthenticated, 
  getRequest, 
  postRequest, 
  patchRequest, 
  deleteRequest,

  authVerify
}
